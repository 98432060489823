<template>
  <div>
    <v-main v-if="!showTriage" class="signup">
      <v-app-bar v-if="showForm && showCodeFields" app color="primary" fixed dark clipped-left elevate-on-scroll>
        <router-link to="/" class="ml-2 mr-3 hidden-sm-and-down">
          <img v-if="!isDocline" :src="appLogoSecondary" class="img-toolbar" alt="logo" />
          <d-logo v-if="isDocline" :slogan="false" primary-color="#ffffff" secondary-color="#ffffff" height="28" />
        </router-link>
      </v-app-bar>

      <v-layout v-show="showCodeFields" align-center justify-center align-content-center mt-12>
        <v-card align-center justify-center class="px-5 py-10" width="600px">
          <div style="text-align: center;">
            <img src="/static/triage.svg" alt="redirect" />
            <p class="migration-title px-sm-2 px-0 pt-7">
              {{ $t('triage.type_code') }}
            </p>
          </div>

          <div
            v-show="showCodeFields"
            class="pincode-dialog__input"
            :class="{ 'pincode-dialog__input--has-errors': invalidCodeError }"
          >
            <pincode-input ref="pincode" v-model="code" :length="codeLength" @input="handleCodeInput" />
            <span v-if="invalidCodeError" class="pincode-dialog__error mt-3">{{ invalidCodeErrorText }}</span>
          </div>

          <div class="text-center mt-6">
            <v-btn
              text
              rounded
              depressed
              :color="!disableAcceptButton ? 'primary' : 'secondary'"
              class="px-5"
              :disabled="disableAcceptButton"
              @click="validateTriageCode"
            >
              {{ $t('triage.start') }}
            </v-btn>
          </div>
        </v-card>
      </v-layout>
    </v-main>

    <v-main v-if="showTriage" class="triage">
      <iframe v-if="token" id="hf-iframe" :src="urlSymptomChecker" title="symptomChecker" />
      <div class="text-center align-center justify-center mt-6">
        <v-btn
          v-show="showFinishButton"
          text
          rounded
          depressed
          color="primary"
          class="px-5 mb-5"
          @click="finishTriageTest"
        >
          {{ $t('triage.finish') }}
        </v-btn>
      </div>
    </v-main>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DLogo from '@/components/DLogo.vue';
import PincodeInput from 'vue-pincode-input';
import { TWO_FA_CODE_LENGTH } from '@/config/constants';
import { createTriagePdfUrl, verifyTriageCodeUrl } from '@/config/config';

export default {
  components: { DLogo, PincodeInput },
  data() {
    return {
      code: '',
      codeLength: TWO_FA_CODE_LENGTH,
      disableAcceptButton: true,
      invalidCodeError: false,
      invalidCodeErrorText: this.$t('2fa.invalidCodeError'),
      showForm: true,
      showCodeFields: true,
      showTriage: false,
      symptomCheckerUrl: 'https://healthform-docline.infermedica.com',
      language: 'en',
      token: '',
      showFinishButton: false,
      patientId: 0,
      connectedAsProfessional:
        JSON.parse(window.localStorage.getItem('auth_ehealth')) !== null &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['user']['rol'] === 2,
      connectedAsReceptionist:
        JSON.parse(window.localStorage.getItem('auth_ehealth')) !== null &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['user']['rol'] === 3,
    };
  },
  computed: {
    toolbarColor() {
      return this.$vuetify.options.extra.mainNav;
    },
    urlSymptomChecker() {
      return this.symptomCheckerUrl + '/form/' + this.language + '/?token=' + this.token;
    },

    ...mapState({
      appLogoSecondary: state => state.app.images.logoSecondary,
      appMenuLogoBackground: state => state.app.styles.menuLogoBackground,
      appMenuLogoClass: state => state.app.styles.menuLogoClass,
      appThemePrimary: state => state.app.styles.themePrimary,
      appThemeSecondary: state => state.app.styles.themeSecondary,
      appName: state => state.app.name,
    }),

    isDocline() {
      return /^docline/.test(this.appName);
    },
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage);
    this.getToken();
    this.getTriageCodeFromParamsOrHash();
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage);
  },
  methods: {
    ...mapActions('layout', ['showAlert']),

    getToken() {
      //obtain token from API ENDPOINT HERE !!
      this.token = 'xxx';
    },
    getTriageCodeFromParamsOrHash() {
      const param = this.$route.query.code;
      const hash = location.hash.substr(1);
      this.hideInputs();
      if (param && param.length === 6) {
        this.code = param;
        this.validateTriageCode();
      } else if (hash && hash.length === 6) {
        this.code = hash;
        this.validateTriageCode();
      } else {
        this.code = '';
        this.showImputs();
      }
    },
    hideInputs() {
      this.showCodeFields = false;
      this.disableAcceptButton = false;
    },
    showImputs() {
      this.showCodeFields = true;
      this.disableAcceptButton = true;
      this.showTriage = false;
    },
    validateTriageCode() {
      const data = {
        code: this.code,
      };
      this.$http
        .post(verifyTriageCodeUrl, JSON.stringify(data))
        .then(res => {
          if (res.status === 202 && res.data === 'valid') {
            this.showTriage = true;
          } else {
            this.markInvalidCode();
          }
        })
        .catch(err => {
          const textAlert = err.data && err.data.error ? this.$t(err.data.error) : this.$t('errors.try_again');
          this.showAlertMsg('error', '', textAlert);
          this.markInvalidCode();
        });
    },
    markInvalidCode() {
      this.showTriage = false;
      this.showCodeFields = true;
      this.disableAcceptButton = true;
      this.invalidCodeError = true;
      this.invalidCodeErrorText = this.$t('triage.code_error');
    },
    finishTriageTest() {
      this.getToken();
      this.showTriage = false;
      if (this.connectedAsProfessional || this.connectedAsReceptionist) {
        this.$router.push('paciente/' + this.patientId);
      }
    },
    handleCodeInput() {
      this.disableAcceptButton = true;
      this.invalidCodeError = false;

      if (this.code.length < this.codeLength) return;
      this.disableAcceptButton = false;
    },
    receiveMessage(event) {
      try {
        const data = JSON.parse(event.data, function(k, v) {
          if (v && typeof v === 'object' && !Array.isArray(v)) {
            return Object.assign(Object.create(null), v);
          }
          return v;
        });

        if (data.action && data.action === 'interview-finished') {
          this.sendDataToAPI(data.data);
        }
      } catch (e) {
        //console.log(e);
      }
    },
    sendDataToAPI(data) {
      data.code = this.code;

      this.$http
        .post(createTriagePdfUrl, JSON.stringify(data))
        .then(res => {
          if (res.status === 201) {
            this.showFinishButton = true;
            this.patientId = res.data.patientId;
            this.code = '';
            this.showCodeFields = true;
          }
        })
        .catch(err => {
          const textAlert = err.data && err.data.error ? this.$t(err.data.error) : this.$t('errors.try_again');
          this.showAlertMsg('error', '', textAlert);
        });
    },
    showAlertMsg(color, icon, msg) {
      this.showAlert({
        color: color,
        icon: icon,
        message: msg,
      });
    },
  },
};
</script>
<style scoped lang="css">
#hf-iframe {
  border: 0;
  height: 860px;
  width: 100%;
}
.signup {
  max-width: 1080px;
  margin: 0 auto !important;
  float: none !important;
  padding: 20px;
}
.triage {
  margin: 0 auto !important;
  float: none !important;
  padding: 20px;
}
.component-fade-enter-active {
  transition: opacity 0.3s ease;
}
.component-fade-leave-active {
  transition: opacity 0.15s ease;
}
.component-fade-enter, .component-fade-leave-to
  /* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.img-toolbar {
  object-fit: contain;
  vertical-align: middle;
  max-height: 48px;
  max-width: 176px;
  width: auto;
  height: auto;
}

.migration-title {
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.85);
}
.migration-text a {
  text-decoration: none;
}
</style>
<style lang="scss" scoped>
.pincode-dialog {
  &__input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ::v-deep input {
      @extend %two-fa-input;
    }

    &--has-errors {
      ::v-deep input {
        border-color: #ff5454;
      }
    }
  }

  &__error {
    color: #ff5454;
  }

  &__close-button {
    position: absolute;
    top: rem(4);
    right: rem(4);

    @include breakpoint(md) {
      position: relative;
    }
  }
}
</style>
